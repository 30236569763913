import { Route, Switch } from 'react-router'
import { ActionTypes } from '../utils'
import DetailsScreen from './DetailsScreen'
import FooterComponent from './FooterComponent'
import GiftFormComponent from './GiftFormComponent'
import HeaderComponent from './HeaderComponent'
import HomeScreen from './HomeScreen'
import NameModal from './NameModal'
import ProfileScreen from './ProfileScreen'
import ProtectedRoute from './ProtectedRoute'

const MainContainer = () => {
  return (
    <div className="App">
      <NameModal/>
      <HeaderComponent />
      <Switch>
        <div id="body">
          <ProtectedRoute path="/new" component={() => <GiftFormComponent method={ActionTypes.POST} />} />
          <ProtectedRoute path="/profile" component={ProfileScreen}/>
          <ProtectedRoute path="/:currentUser/:giftId" component={DetailsScreen}/>
          <Route exact path="/">
            <HomeScreen />
          </Route>
        </div>
      </Switch>
      <FooterComponent />
    </div>
  )
}

export default MainContainer