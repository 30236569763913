import { useCallback, useEffect, useState } from "react"
import { useParams, useHistory } from "react-router"
import { ActionTypes, config, currentUserApi, DEFAULT_IMG, DetailsActionTypes, DetailsScreenProps, Gift } from "../utils"
import LoadingComponent from "./LoadingComponent"
import * as yup from "yup";
import {useCryptr} from '@cryptr/cryptr-react'
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import GiftFormComponent from "./GiftFormComponent";
import DetailsDescription from "./DetailsDescription";
import { Link } from "react-router-dom";

const schema = yup.object({
  product_price: yup.number().positive().integer(),
});

const DetailsScreen = () => {
  const {user} = useCryptr()
  const history = useHistory()
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema)
  });
  const [action, setAction] = useState<DetailsActionTypes>(DetailsActionTypes.Show)
  const [refreshing, setRefreshing] = useState(false)
  const [fetchedGift, setFetchedGift] = useState(false)
  const [sessionUser, setSessionUser] = useState<string>()
  const [gift, setGift] = useState<Gift>()
  const {currentUser, giftId} = useParams() as DetailsScreenProps

  useEffect(() => {
    if(user() && user() !== undefined && Object.keys(user().resource_owner_metadata).length > 0) {
      const {user_name} = user().resource_owner_metadata
      if(user_name) {
        setSessionUser(user_name)
      }
    }
  }, [user])

  const fetchGift = useCallback(() => {
    setRefreshing(true)
    
    fetch(currentUserApi(currentUser), config)
      .then(res => res.json())
      .then(res => {
        setRefreshing(false)
        setFetchedGift(true)
        setGift(res.records.find((g: Gift) => g.id === giftId))
      })
      .catch(error => {
        setFetchedGift(true)
        setRefreshing(false)
      })
  }, [currentUser, giftId])

  useEffect(() => {
    if(!fetchedGift) {
      console.warn("not fetchedGifs")
      fetchGift()
    }
  }, [fetchGift, fetchedGift])

  if(action === DetailsActionTypes.Edit) {
    return(
      <GiftFormComponent gift={gift} method={ActionTypes.PATCH} />
    )
  } else {
    if(refreshing || !gift) {
      return <LoadingComponent />
    } else {
      const {fields: {description, Name, pots, product_image, product_price, product_price_page}} = gift
      const parsedPots = JSON.parse(pots)
      const participant_count = Object.keys(parsedPots).length
      const foundedValue = Object.values(parsedPots).reduce((r, n) => (r as number) + (n as number), 0)
      const progress = participant_count > 0 ? (foundedValue as number * 100) / product_price : 0
  
      const maxParticipation = foundedValue ? product_price - (foundedValue as number) : product_image

      const scopedStyle = `body { backdrop-filter: blur(10px); height: 100vh; background-size: cover; background-repeat: no-repeat; background-image: url('${product_image}')}`
  
      const onSubmit = ({potValue}) => {
        if(JSON.parse(pots) !== undefined && sessionUser) {
          var newPots = JSON.parse(pots)
          newPots[`${sessionUser}`] = parseInt(potValue)
          const giftParams = {...gift, createdTime: undefined,fields: {pots: JSON.stringify(newPots)}}
  
          const body = {records: [giftParams]}
          let newConfig = {...config, method: ActionTypes.PATCH, body: JSON.stringify(body)}
          fetch(currentUserApi(currentUser), newConfig)
          .then(res => res.json())
          .then(res => {
            history.go(0)
          })
          .catch(error => {
            console.error(error)
          })
        }
      }

      const productImage = product_image || DEFAULT_IMG

      return (
        <section className="text-gray-600 body-font overflow-hidden">
          <style scoped>
            {scopedStyle}
          </style>
          <div className="rounded-md container px-5 py-24 mx-auto" style={{backgroundColor: "rgba(255,255,255,0.85)"}}>
            <div className="lg:w-4/5 mx-auto flex flex-wrap">
              <img 
                alt="ecommerce" 
                className="lg:w-1/2 w-full lg:h-auto h-64 object-contain object-center rounded" 
                src={productImage}
              />
              <div className="lg:w-1/2 w-full lg:pl-10 lg:py-6 mt-6 lg:mt-0">
                <h2 className="text-sm title-font text-gray-500 tracking-widest">
    
                </h2>
                <h1 className="text-gray-900 text-3xl title-font font-medium mb-1">
                  {Name}
                </h1>
                {sessionUser && sessionUser !== currentUser && (
                  <div className="flex mb-4">
                    <span className="flex-2 items-center align-middle">
                      <span className="text-gray-600 ml-3">
                        {`${participant_count} participant(s)`}
                      </span>
                    </span>
                    <span className="flex-1 ml-3 pl-3 py-2 border-l-2 border-gray-200 space-x-2s">
                      <div className="relative pt-1">
                        <div className="flex mb-2 items-center justify-between">
                          <div>
                            <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-indigo-600 bg-indigo-200">
                              Progression
                            </span>
                          </div>
                          <div className="text-right">
                            <span className="text-xs font-semibold inline-block text-indigo-600">
                              {`${foundedValue}€`}
                            </span>
                          </div>
                        </div>
                        <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-indigo-200">
                          <div style={{width: `${progress}%`}}
                            className="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-indigo-500">
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                )}
                <DetailsDescription description={description} />
                <div className="text-left">
                  {product_price_page && (<a href={product_price_page} target="_blank" className="inline-flex items-center h-10 px-2 text-indigo-700 transition-colors duration-150 border border-indigo-700 rounded-lg focus:shadow-outline hover:text-white hover:bg-indigo-800">
                    <span>En savoir plus</span>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                    </svg>
                  </a>)}
                </div>
                <hr className="my-4"/>
                {sessionUser && currentUser !== sessionUser && (
                  <>
                    <div className="text-left">
                      {participant_count > 0 ? Object.keys(parsedPots).map((name: any) =>
                        <button key={name} className="h-8 px-2 mr-2 text-gray-400 border border-indigo-200 transition-colors duration-150 rounded-full focus:shadow-outline hover:bg-gray-200">
                          <span className="mx-2">{name}</span>
                          <span className="inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-indigo-400 rounded-full">
                            {`${parsedPots[name]} €`}
                          </span>
                        </button>
                      ): (
                        <p className="text-gray-400">
                          Aucun participant pour le moment
                        </p>
                      )}
                    </div>
                    <hr className="my-4"/>
                  </>
                )}
                <div className="flex mt-4 text-lef bg-whitet">
                  <span className="title-font font-medium text-2xl text-indigo-900">
                    {`${product_price} €`}
                  </span>
                  {sessionUser && currentUser !== sessionUser ? (
                    <form onSubmit={handleSubmit(onSubmit)} className="w-full" style={{display: "contents"}}>
                      <div className="bg-white w-1/2 mx-4 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600">
                        <label
                          htmlFor="name"
                          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
                        >
                          Montant
                        </label>
                        <input
                          type="number"
                          id="pot-value"
                          className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
                          placeholder="5€"
                          min={1}
                          max={maxParticipation}
                          required={true}
                          {...register("potValue", {min: 1, max:  maxParticipation, required: true})}
                        />
                        {errors.potValue && <span>Mauvise entrée</span>}
                      </div>
                      {Object.keys(parsedPots).indexOf(sessionUser) === -1 ? (
                        <button className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                          Je participe
                        </button>
                      ): (
                        <button className="flex ml-auto text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded">
                          Je change
                        </button>
                      )}
                    </form>
                  ) : (
                    <>
                      <p className="border-l ml-2 pl-2">
                        Tu n'auras pas accès à la progression &#128540;
                        <span>Mais&nbsp;
                          <strong onClick={() => setAction(DetailsActionTypes.Edit)} className="text-indigo-900 cursor-pointer">clique ici pour modifier</strong> 
                          </span>
                      </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
}

export default DetailsScreen