import React from 'react'
import { Link } from 'react-router-dom'
import { DEFAULT_IMG, GifItemProps } from '../utils'
import DetailsDescription from './DetailsDescription'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


const GiftItem = ({currentUser, gift, sessionUser}: GifItemProps) => {
  const {id, fields} = gift
  const {description, Name, product_image, product_price_page, product_price, pots} = fields
  const divId = `gift-${id}`


  const parsedPots = JSON.parse(pots)

  const fundedValue = parsedPots === {} ? 0 : Object.values(parsedPots).reduce((r, n) => (r as number) + (n as number), 0)
  const link = `/${currentUser}/${id}`

  const productImage = product_image || DEFAULT_IMG
  if(isMobile) {
    return (
      <div id={divId} className="p-4 sm:w-1/2 md:w-1/3 lg:w-1/4">
        <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
          <Link to={link}>
            <img className="lg:h-48 md:h-36 w-full object-contain object-center" src={productImage} alt={Name} />
          </Link>
          <div className="p-6">
            <Link to={link}>
              <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                {/* {createdTime} */}
              </h2>
              <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                {Name}
              </h1>
              <p className="leading-relaxed mb-3">
                {product_price} €
              </p>
            </Link>
            <div className="flex items-center flex-wrap ">
              <a href={product_price_page} target="_blank" rel="noreferrer" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                En savoir plus
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
              {currentUser !== sessionUser ? (
                <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  {Object.keys(parsedPots).length}
                </span>
              ): (
                <></>
              )}
              <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                {currentUser !== sessionUser ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                    </svg>
                    {fundedValue} €
                  </>
                ) : (
                  <span>-</span>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <BrowserView className="flip-card p-4 sm:w-1/2 md:w-1/3 lg:w-1/4">
      <div id={divId} className="flip-card-inner">
        <div className="flip-card-front h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
          <Link to={link}>
            <img className="lg:h-48 md:h-36 w-full object-contain object-center" src={productImage} alt={Name} />
          </Link>
          <div className="p-6">
            <Link to={link}>
              <h2 className="tracking-widest text-xs title-font font-medium text-gray-400 mb-1">
                {/* {createdTime} */}
              </h2>
              <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                {Name}
              </h1>
              <p className="leading-relaxed mb-3">
                {product_price} €
              </p>
            </Link>
            <div className="flex items-center flex-wrap ">
              <a href={product_price_page} target="_blank" rel="noreferrer" className="text-indigo-500 inline-flex items-center md:mb-2 lg:mb-0">
                En savoir plus
                <svg className="w-4 h-4 ml-2" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M5 12h14"></path>
                  <path d="M12 5l7 7-7 7"></path>
                </svg>
              </a>
              {currentUser !== sessionUser ? (
                <span className="text-gray-400 mr-3 inline-flex items-center lg:ml-auto md:ml-0 ml-auto leading-none text-sm pr-3 py-1 border-r-2 border-gray-200">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                  {Object.keys(parsedPots).length}
                </span>
              ): (
                <></>
              )}
              <span className="text-gray-400 inline-flex items-center leading-none text-sm">
                {currentUser !== sessionUser ? (
                  <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                    </svg>
                    {fundedValue} €
                  </>
                ) : (
                  <span>-</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="flip-card-back h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
          <Link to={link}>
          </Link>
          <div className="h-full p-6">
            <Link to={link} className="h-full w-full align-middle my-auto">
              <DetailsDescription description={description} className="h-full w-full" />
            </Link>
          </div>
        </div>
      </div>
    </BrowserView>
  )
}

export default GiftItem
