import React, { useCallback, useEffect } from 'react'
import { useState } from "react"
import { btnGroupClass, config, currentUserApi, Gift, sortGifts, SortType, users } from '../utils'
import EmptyResultsComponent from './EmptyResultsComponent'
import GiftItem from "./GiftItem"
import LoadingComponent from './LoadingComponent'
import SortGiftsComponent from './SortGiftsComponent'
import {useCryptr} from '@cryptr/cryptr-react'
import { Link } from 'react-router-dom'

const HomeScreen = () => {
  const { isAuthenticated, user } = useCryptr()

  const [refreshing, setRefreshing] = useState(false)
  const [fetchedGifts, setFetchedGifts] = useState(false)
  const [gifts, setGifts] = useState<Gift[]>([])
  const [sessionUser, setSessionUser] = useState<string>()
  const [currentUser, setCurentUser] = useState<string>()
  const [currentSort, setCurrentSort] = useState<SortType>(SortType.None)
  const currentUsers = users()

  useEffect(() => {
    if(user() && user() !== undefined && Object.keys(user().resource_owner_metadata).length > 0) {
      const {user_name} = user().resource_owner_metadata
      if(user_name) {
        setCurentUser(user_name)
        setSessionUser(user_name)
      }
    }
  }, [user])
  
  const fetchGifts = useCallback(() => {
    setRefreshing(true)
    currentUser && fetch(currentUserApi(currentUser), config)
      .then(res => res.json())
      .then(res => {
        setRefreshing(false)
        setFetchedGifts(true)
        setGifts(res.records)
      })
      .catch(error => {
        setFetchedGifts(true)
        setRefreshing(false)
      })
  }, [currentUser])


  useEffect(() => {
    console.log("not fetchedGifs")
    fetchGifts()
  }, [fetchGifts, fetchedGifts, currentUser])

  if(isAuthenticated()) {
    return (
      <div>
        {currentUsers.length > 0 && (
          <span className="relative z-0 inline-flex shadow-sm rounded-md">
            {currentUser && currentUsers?.map((user, index) => {
              const textColor = user === currentUser ? 'text-indigo-700' : 'text-gray-700'
  
              return (
                <button
                  type="button"
                  key={user}
                  onClick={() => setCurentUser(user)}
                  className={`${btnGroupClass(index, currentUsers.length)} relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium ${textColor} hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                >
                  {user}
                </button>
              )
              }
            )}
          </span>
        )}
        {gifts && gifts.length > 0 && (
          <SortGiftsComponent currentSort={currentSort} setCurrentSort={setCurrentSort} />
        )}
        <div className="mt-8">
          {!refreshing && currentUser && sessionUser ? (
            <section className="text-gray-600 body-font">
              <div className="container px-5 pb-24 mx-auto">
                <div className="flex flex-wrap -m-4">
                  {gifts && gifts.length > 0 ? (
                    <>
                      {
                        sortGifts(gifts, currentSort).map((gift: Gift) => {
                          const { id } = gift
        
                          return (
                            <GiftItem key={id} currentUser={currentUser} sessionUser={sessionUser} gift={gift} />
                          )
                        })
                      }
                      {sessionUser && currentUser === sessionUser && (
                        <div className="p-4 sm:w-1/2 md:w-1/3 lg:w-1/4 text-center">
                          <div className="h-full border-2 border-gray-200 border-opacity-60 rounded-lg overflow-hidden">
                            <Link to="/new">
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-32 w-32 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                              </svg>
                              <div className="p-6">
                                Ajouter un autre souhait
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <EmptyResultsComponent sessionUser={sessionUser} user={currentUser} />
                  )}
                  
                </div>
              </div>
            </section>
          ) : (
            <LoadingComponent />
          )}
        </div>
      </div>
    )
  } else {
    return (
      <span>Veuillez vous connecter</span>
    )
  }

}

export default HomeScreen