import React from 'react';
import './App.css';
import {BrowserRouter as Router} from 'react-router-dom'
import { CryptrProvider } from '@cryptr/cryptr-react'
import MainContainer from './components/MainContainer';

function App() {
  const cryptrConfig = {
    audience: process.env.REACT_APP_CRYPTR_AUDIENCE,
    client_id: process.env.REACT_APP_CRYPTR_CLIENT_ID,
    default_locale: process.env.REACT_APP_CRYPTR_DEFAULT_LOCALE,
    default_redirect_uri: process.env.REACT_APP_CRYPTR_DEFAULT_REDIRECT_URI,
    telemetry: process.env.REACT_APP_CRYPTR_TELEMETRY,
    cryptr_base_url: process.env.REACT_APP_CRYPTR_BASE_URL,
    tenant_domain: process.env.REACT_APP_CRYPTR_TENANT_DOMAIN
  }
  
  return (
    <CryptrProvider {...cryptrConfig}>
      <Router>
        <MainContainer />
      </Router>
    </CryptrProvider>
  );
}

export default App;
