import React from 'react'
import { Route } from "react-router-dom"
import { useCryptr } from '@cryptr/cryptr-react'
 
 
const ProtectedRoute = ({ component: Component, ...rest }) => {
   const {isAuthenticated, isLoading, signinWithRedirect} = useCryptr() 

   return (
       <Route {...rest} render={(props) => (
           isLoading ? (
                <span> veuillez patienter </span>
            ) : (isAuthenticated() ? (
                <Component {...props} />
            ) : signinWithRedirect())
       )} />
   )
}
 
export default ProtectedRoute