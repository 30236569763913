import React from 'react';
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'
import remarkImages from 'remark-images'
import remarkGemoji from 'remark-gemoji'
import { DetailsDescriptionProps } from '../utils';

const DetailsDescription = ({className, description}: DetailsDescriptionProps) => {
  const plugins = [remarkImages, remarkGemoji, remarkGfm, remarkBreaks]
  return (
    <>
      {description && (
        <ReactMarkdown 
          className={`${className} leading-relaxed text-left`}
          components={{
            ul: ({node, ...ulrops}) => <ul className="list-inside list-disc text-left w-max my-2" {...ulrops} />,
            ol: ({node, ...olrops}) => <ol className="list-inside list-decimal text-left w-max my-2" {...olrops} />,
            p: ({node, ...props}) => <p className="py-2" {...props} />,
            a: ({node, ...props}) => <a className="text-indigo-600 underline" {...props} target="_blank" />,
          }}
          remarkPlugins={plugins}>
          {description}
        </ReactMarkdown>
      )}
    </>
  )
}

export default DetailsDescription