export const config = {
  method: 'GET',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_API_BEARER}`,
  },
};

export const users = () : Array<string> => {
  const tables = process.env.REACT_APP_API_TABLES
  if (tables) {
    return tables.split(',').sort()
  }
  return []
}

export const graphqlUrl = (): string => {
  return process.env.REACT_APP_GRAPHQL_URL || ''
}

export const btnGroupClass = (index: number, arrayLength: number) => {
  switch (index) {
    case 0:
      return "rounded-l-md"
    case arrayLength - 1:
      return "-ml-px rounded-r-md"
    default:
      return "-ml-px"
  }
}

export const currentUserApi = (user: string) => {
  return `https://api.airtable.com/v0/${process.env.REACT_APP_API_TABLE_PATH}${user}`;
}

export const DEFAULT_IMG = 'https://images.unsplash.com/photo-1608232034071-c604ddc8470a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1006&q=80'

export enum SortType {
  None,
  Price,
  Name,
  Participant,
  Founded,
}

export const sortGifts = (gifts: Gift[], currentSort: SortType): Gift[] => {
  switch (currentSort) {
    case SortType.Name:
      return gifts.sort((a: Gift, b: Gift) => a.fields.Name.localeCompare(b.fields.Name))
    case SortType.Participant:
      return gifts.sort((a: Gift, b: Gift) => 
        Object.keys(JSON.parse(b.fields.pots)).length - Object.keys(JSON.parse(a.fields.pots)).length
      )
    case SortType.Founded:
      return gifts.sort((a: Gift, b: Gift) => 
        (Object.values(JSON.parse(b.fields.pots)).reduce((r, n) => (r as number) + (n as number), 0) as number)
        - (Object.values(JSON.parse(a.fields.pots)).reduce((r, n) => (r as number) + (n as number), 0) as number)
      )
    case SortType.Price:
      return gifts.sort((a: Gift, b: Gift) => 
        a.fields.product_price - b.fields.product_price
      )
    default:
      return gifts
  }
}

export type GiftField = {
  description: string
  Name: string
  pots: string
  product_image: string
  product_price: number
  product_price_page: string
}

export type Gift = {
  id: string
  createdTime: string
  fields: GiftField
}

export type DetailsScreenProps = {
  currentUser: string
  giftId: string
}

export type GifItemProps = {
  currentUser: string
  gift: Gift
  sessionUser: string
}

export type EmptyResProps = {
  sessionUser: string
  user: string
}

export type SortProps = {
  currentSort: SortType
  setCurrentSort: (sort: SortType) => void
}

export type NewProps = {
  method: ActionTypes
  gift?: Gift
}

export type DetailsDescriptionProps = {
  description?: string
  className?: string
}

type NewType = {
  name: string
  id: string
  required: boolean
  type: string
}

export type MetaData = {
  key: NewType
  value: any
}

export enum ActionTypes  {
  POST = 'POST',
  PATCH = 'PATCH'
}

export enum DetailsActionTypes  {
  Show,
  Edit
}