import React from "react"
import { Link } from "react-router-dom"
import { EmptyResProps } from "../utils"

export const EmptyResultsComponent = ({user, sessionUser}: EmptyResProps) => {
    return (
    <div className="min-h-full pt-16 pb-12 flex flex-col bg-white w-full">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <span className="sr-only">Workflow</span>
              <svg xmlns="http://www.w3.org/2000/svg" className="h-12 w-auto text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">Rien ici</p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Aucun cadeau n'a été listé
              </h1>
              {sessionUser && user === sessionUser ? (
                  <p className="mt-2 text-base text-gray-500">
                    Tu n'as encore rien proposé, 
                    <Link to="new">
                      <strong className="text-indigo-700">clique ici </strong>
                    </Link>
                    pour créer ton premier souhait avec que les autres puissent y réfléchir
                  </p>
              ) : (
                <p className="mt-2 text-base text-gray-500">
                  Peut être que <strong className="text-indigo-700">{user}</strong> n'a pas envie de cadeaux pour Noël, ou qu'il/elle n'a pas été assez sage
                </p>
              )}
            </div>
          </div>
        </main>
      </div>
    )
}

export default EmptyResultsComponent